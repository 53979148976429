import React, { useEffect, useState, useContext, useRef } from 'react'
import { Stack } from '@mui/material'
import { TAppContext, AppContext, TAppConfig, TSECNote, default_sec_note } from '../../../../types.tsx'
import { apr_font_highlight, apr_font_default, apr_background_highlight, apr_background_default } from '../../../../globals.tsx'

import "./SmartTickerButton.css"

const menuItemStyle = {
  padding: "8px 12px",
  cursor: "pointer",
  backgroundColor: "#eee",
  marginBottom: "4px",
};

export default function SECNotes({ symbol, activeSymbol, onClicked, onMenuSelection }) {
	const [menuVisible, setMenuVisible] = useState(false)
  const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 })
  const [selectedItem, setSelectedItem] = useState(null)
	
	const handleRightClick = (event) => {
		event.preventDefault() // Prevent default right-click menu

		// Set position of custom menu
		setMenuPosition({ x: event.pageX, y: event.pageY })
		setMenuVisible(true)
	}

	const handleClickOutside = () => {
		setMenuVisible(false)
	}

	const handleItemClick = (item) => {
		setSelectedItem(item)
		setMenuVisible(false)
		console.log(`${symbol}: ${item}`)
	}

	let menu_items:string[] = ["Finviz", "Yahoo!", "Stocktwits"]

	return (
		<div>
			<button 
				onContextMenu={handleRightClick}
				onClick={(e) => { onClicked(symbol) }} 
				value={symbol} 
				style={{ 
					padding: 2, 
					textAlign:'center', 
					border:'solid', 
					borderWidth:1, 
					borderColor:'#ccc #000 #000 #ccc', 
					color: activeSymbol == symbol ? apr_font_highlight : apr_font_default, 
					backgroundColor: activeSymbol == symbol ? apr_background_highlight : apr_background_default,
					marginRight: 3, 
					marginBottom: 2
				}}
				key={symbol} 
			>
				{ symbol }
			</button>
			{menuVisible && (
        <ul
					onMouseLeave={() => { setMenuVisible(false) }}
          style={{
            position: "absolute",
            top: menuPosition.y,
            left: menuPosition.x,
            backgroundColor: "#f0f0f0",
            listStyleType: "none",
            padding: "10px",
            border: "1px solid #ccc",
          }}
        >
					{
						menu_items.map(item => {
							return (
								<li
									onClick={(e) => { onMenuSelection(symbol, item); handleItemClick(item) }}
									style={menuItemStyle}
									onMouseEnter={(e) => e.target.style.backgroundColor = "#d3d3d3"}
									onMouseLeave={(e) => e.target.style.backgroundColor = "#eee"}
									key={item}
								>
									{item}
								</li>
							)
						})
					}
        </ul>
      )}
		</div>
	)
}