import React from 'react'
import { Stack } from '@mui/material'
import { status_height } from "../globals.tsx"

import './StatusPanel.css'

export default function NotificationsPanel(
		{ notificationsHistory, clearNotificationsHistory }
		:{ notificationsHistory: string[], clearNotificationsHistory: () => void}
	) {
	return (
		<Stack direction='row' sx={{ width: "100%", marginLeft: 2 }}>
			<button className='statuspanel_button' onClick={() => { clearNotificationsHistory() }}>
				Clear Notifications History
			</button>
			<div 
				style={{
					flex: 1,
					height: status_height, 
					backgroundColor: '#f2f2f2', 
					borderTop: '2px solid #aaa', 
					borderLeft: '2px solid #aaa',
					borderBottom: '2px solid #eee', 
					borderRight: '2px solid #eee',
					overflowY: 'scroll',
					overflowX: 'visible',
					whiteSpace: 'wrap',
					padding: '5px',
					marginLeft: '5px'
				}}
			>
			{
				notificationsHistory.map((text: string, i: number) => {
					return (
						<div style={{color:(i===0 ? '#000':'#aaa')}} key={text}>
							{text}
						</div>
					)
				})
			}
			</div>
		</Stack>
	)
}