import React, { useContext } from 'react'
import { Box, Stack, Typography } from '@mui/material'
import { useNavigate, useLocation, NavLink } from 'react-router-dom'
import VerticalSelection from './VerticalSelection.tsx'
import { TAppContext, AppContext, TAppConfig } from './types.tsx'
import { sidebar_width, app_background } from './globals.tsx'
import { storeAppConfig } from './utility/go.tsx'

import './Sidebar.css'

import USMarketsIcon from '@mui/icons-material/Visibility'
import AccountIcon from '@mui/icons-material/ManageAccounts'
import LogoutIcon from '@mui/icons-material/Logout'

export const categories = [  
  { name: 'US Markets', icon: <USMarketsIcon />, link: '/usmarkets' },
  { name: 'Account', icon: <AccountIcon />, link: '/account' },
  { name: 'Logout', icon: <LogoutIcon />},
]

export const top_level_categories = [  
  { name: 'US Markets', icon: <USMarketsIcon />, link: '/usmarkets' },
]

export const bottom_level_categories = [  
  { name: 'Account', icon: <AccountIcon />, link: '/account' },
  { name: 'Logout', icon: <LogoutIcon />},
]

// Purpose:
export default function Sidebar() {
  const {config, setConfig}:TAppContext = useContext(AppContext)
  const navigate = useNavigate()

  const handleCategorySelection = (newcategory) => {
    if (newcategory === 'Logout') {      
      setConfig(o => { return {...config, in_session: false, session_token: ''}})
      storeAppConfig(config)
      navigate('/login')
    }
  }

  const location = useLocation()
  const selectedCategory = categories.find((category) => category.link && category.link === location.pathname)

  return (
    <div style={{zIndex:-2, float:'left'}}>
      <Stack
        direction={{xs:'row', sm:'row', md:'column'}}
        sx={{
				  height:{xs:'50px', sm:'50px', md:'100vh'},
				  width:{xs:'100vw', sm:'100vw', md:sidebar_width},
				  background: app_background,
				  paddingLeft:'2px',
        }}
      >
        <Box sx={{display:{xs:'none', sm:'none', md:'block'}}}>
          <img src="/images/logo.jpg" width={sidebar_width - 5} alt="buildview" onClick={() => handleCategorySelection('US Markets')} />
        </Box>
        <span style={{color:'#ccc', textAlign:'center', fontWeight:'bold', fontSize:16}}>
          
        </span>
        <Box sx={{display:{xs:'none', sm:'none', md:'block'}}}>
          <VerticalSelection selectedCategory={selectedCategory ? selectedCategory.name : 'US Markets'} />
        </Box>
        <Box sx={{
          width:'100%',
          display:{xs:'inline-flex', sm:'inline-flex', md:'block'},
          overflowY:'scroll'
        }}>
          <Box sx={{marginLeft:0, width:{xs:'85%', sm:'85%', md:'100%'}, display:'flex', flexFlow:'wrap'}}>
            {top_level_categories.map((category) => (
              <Box key={category.name} sx={{
                width:{xs:128, sm:128, md:sidebar_width},
                marginTop:{xs:0, sm:0, md:1}
              }}>
                <NavLink
                  to={category.link}
                  className="category-btn"
                  style={({isActive}) => { 
                    return {background:category.link && isActive && '#bbb', color: isActive ? 'white' : '#bbb'}
                  }}
                  key={category.name}
                  onClick={() => handleCategorySelection(category.name)}
                >
                  <span style={{marginRight:'5px'}}>
                    {category.icon}
                  </span>
                  <Typography sx={{whiteSpace:{xs:'nowrap', sm:'nowrap', md:'break-spaces'}}}>
                    {category.name}
                  </Typography>
                </NavLink>
              </Box>
            ))}
          </Box>
          <Box sx={{marginLeft:'auto', margin:{xs:0, sm:0, md:'10px 2px 10px 0'}, height:'auto'}}>
            <Box sx={{height:{xs: 0, sm:0, md:'15px'}, marginBottom:{xs:0, sm:0, md:'-5px'}, borderTop:'1px solid #ccc'}}></Box>
            {bottom_level_categories.map((category) => (
              <Box 
                sx={{
                  marginBottom:{xs:0, sm:0, md:'15px'},
                  marginLeft: {xs: '10px', sm: '10px', md: '0'}, 
                  marginRight: {xs: '10px', sm: '10px', md: '0'}
                }}
                key={category.name}
              >
                <NavLink
                  to={category.link}
                  className="category-btn"
                  style={({isActive}) => { 
                    return {background:category.link && isActive && '#bbb', color:isActive ? 'white' : '#bbb'}
                  }}
                  key={category.name}
                  onClick={() => handleCategorySelection(category.name)}
                >
                  <span style={{marginRight:'5px'}}>
                    {category.icon}
                  </span>
                  <Typography sx={{whiteSpace:{xs:'nowrap', sm:'nowrap', md:'break-spaces'}}}>
                    {category.name}
                  </Typography>
                </NavLink>
              </Box>
            ))}
          </Box>
        </Box>
      </Stack>
    </div>
  )
}
