import React from 'react'
import { useState } from 'react'
import { TAppConfig, AppContext } from "./types.tsx"
import { retrieveAppConfig } from './utility/go.tsx'

export function AppContextProvider({children}: any) {
	const initConfig = retrieveAppConfig()
	if (initConfig.session_token !== '') {
		initConfig.in_session = true
	}

	const [cfg, setCfg] = useState<TAppConfig>(initConfig)

	return (
		<AppContext.Provider value={{config: cfg, setConfig: setCfg}}>
			{children}
		</AppContext.Provider>
	)
}