import React, { useEffect, useState, useContext, useRef } from 'react'
import { Stack } from '@mui/material'
import { goAPI, goResult } from '../../../utility/go.tsx'
import { TAppContext, AppContext, TAppConfig, TSECNote, default_sec_note } from '../../../types.tsx'
import { notes_height, notes_width } from '../../../globals.tsx'

import "./SECNotes.css"

enum eEditType {
	text,
	date_text
}
enum eDeleteState {
	idle,
	deleting,
	confirmation,
}

export default function SECNotes({ symbol, appendStatusText }) {
	const {config, setConfig}:TAppContext = useContext(AppContext)	
	const [notes, setNotes] = useState<TSECNote[]>([])
	const [editRow, setEditRow] = useState<number>(-1)
	const [editType, setEditType] = useState<eEditType>(eEditType.text)
	const [editValue, setEditValue] = useState<string>("")
	const [deleteState, setDeleteState] = useState<boolean>(false)

	const loadNotes = () => {
		goAPI("GET", "/secnote/all?symbol=" + symbol).then((go: goResult) => {
			if (go.httpstatus === 200) {
        const log: string[] = go.data.log
				log.forEach(item => {
					appendStatusText(item)
				})
				
				setNotes([...go.data.notes])
      } else if (go.httpstatus === 401) {
        setConfig({...config, in_session: false})
			} else {
        console.log("Unexpected HTTP status: [" + go.httpstatus + ", " + JSON.stringify(go.data) + "]")
      }
		})		
	}

	const doEditRow = (i:number) => {
		setEditValue(notes[i].text)
		setEditRow(i)
	}
	const doDeleteRow = (i:number) => {
		let n:TSECNote = notes[i]

		if (deleteState) {
			goAPI("PUT", "/secnote/delete?id=" + n.id).then((go: goResult) => {
				if (go.httpstatus === 200) {
					const log: string[] = go.data.log
					log.forEach(item => {
						appendStatusText(item)
					})					
					setNotes([...go.data.notes])
					setDeleteState(false)
					setEditRow(-1)
				} else if (go.httpstatus === 400) {
					alert("Client side error")
				} else if (go.httpstatus === 401) {
					setConfig({...config, in_session: false})
				} else {
					console.log("Unexpected HTTP status: [" + go.httpstatus + ", " + JSON.stringify(go.data) + "]")
				}
			})
		} else {
			setEditRow(i)
			setDeleteState(true)
			setEditValue("☚ Click on ! to confirm deletion, x otherwise.")
		}
	}	
	const doCreateRow = () => {
		let ns = [...notes]
		let n:TSECNote = {...default_sec_note}
		ns.push(n)
		setNotes([...ns])
		setEditValue("")
		setEditRow(ns.length - 1)
	}
	const doUpdateRow = () => {
		// [TODO] Call API to either update message text or to create new note

		let n:TSECNote = notes[editRow]
		if (n.id == 0) {
			goAPI("PUT", "/secnote/create?symbol=" + symbol + "&text=" + encodeURIComponent(editValue) + "&type=0&date=0").then((go: goResult) => {
				if (go.httpstatus === 200) {
					const log: string[] = go.data.log
					log.forEach(item => {
						appendStatusText(item)
					})					
					setNotes([...go.data.notes])
					setEditRow(-1)
				} else if (go.httpstatus === 401) {
					setConfig({...config, in_session: false})
				} else {
					console.log("Unexpected HTTP status: [" + go.httpstatus + ", " + JSON.stringify(go.data) + "]")
				}
			})
		} else {
			goAPI("PUT", "/secnote/update?id=" + n.id + "&text=" + encodeURIComponent(editValue) + "&date=0").then((go: goResult) => {
				if (go.httpstatus === 200) {
					const log: string[] = go.data.log
					log.forEach(item => {
						appendStatusText(item)
					})					
					setNotes([...go.data.notes])
					setEditRow(-1)
				} else if (go.httpstatus === 401) {
					setConfig({...config, in_session: false})
				} else {
					console.log("Unexpected HTTP status: [" + go.httpstatus + ", " + JSON.stringify(go.data) + "]")
				}
			})
		}
	}
	const doCancelUpdate = () => {
		let n:TSECNote = notes[editRow]
		if (n.id == 0) {
			let ns:TSECNote[] = [...notes]
			ns.splice(editRow, 1)
			setNotes([...ns])
		} else {
			
		}
		setDeleteState(false)
		setEditRow(-1)
	}	
	const doChangeEditType = (e) => {
		if (e.target.value == 0) {
			setEditType(eEditType.text)
		} else if (e.target.value == 1) {
			setEditType(eEditType.date_text)
		} else {
			alert("Invalid edit index value: " + e.target.value)
		}
	}

	useEffect(() => {
		loadNotes()
	}, [symbol])
	
	return (
		<Stack direction='column' sx={{ backgroundColor: 'ghostwhite', border: '1px solid #ccc' }}>
			<div>Equity Notes:</div>
			<Stack direction="column" overflow="scroll"
				sx={{ 
					width: notes_width, 
					height: notes_height, 
					backgroundColor: '#f2f2f2', 
					borderTop: '2px solid #aaa', 
					borderLeft: '2px solid #aaa',
					borderBottom: '2px solid #eee', 
					borderRight: '2px solid #eee',
					marginRight: 1
 				}}
			>
				{
					notes.map((note, i) => {
						return <Stack direction='row'>
							{
								editRow == -1 ?
									<>
										<button className='secnotes_mini_button' onClick={() => { doEditRow(i) }}>ẟ</button> 
										<button className='secnotes_mini_button' onClick={() => { doDeleteRow(i) }}>-</button>
									</>
									: editRow == i ?
										deleteState ?
											<>
												<button className='secnotes_mini_button' onClick={() => { doDeleteRow(i) }}>!</button> 
												<button className='secnotes_mini_button' onClick={() => { doCancelUpdate() }}>x</button> 
											</>
											: <>
												<button className='secnotes_mini_button' onClick={() => { doUpdateRow() }}>✓</button> 
												<button className='secnotes_mini_button' onClick={() => { doCancelUpdate() }}>x</button> 
											</>
									: <>
											<button className='secnotes_mini_button' disabled={true}>ẟ</button> 
											<button className='secnotes_mini_button' disabled={true}>-</button>
										</>
							}
							{
								editRow == -1 ?
									<span style={{ marginLeft: '5px', width: '100%', fontWeight: 'bold' }}>{ note.text	}</span>
									: editRow == i ?
											deleteState ?
												<span style={{ marginLeft: '5px', width: '100%' }}>{ editValue }</span>
												: <input type="text" value={editValue} style={{ width: '100%' }} id={note.text} onChange={(e) => setEditValue(e.target.value)}/>
									: <span style={{ marginLeft: '5px', width: '100%', fontWeight: 'bold' }}>{ note.text }</span>
							}
						</Stack>
					})
				}
			</Stack>
			<Stack direction='row'>				
				<select className='secnotes_mini_button' onChange={(e) => { doChangeEditType(e) }} >
					<option value="0">Text</option>
					<option value="1">Date</option>
					<option value="2">Checklist Item</option>
				</select>
				<button className='secnotes_mini_button' onClick={() => { doCreateRow() }}>+</button>
			</Stack>
		</Stack>
	)
}