import { TAppConfig, default_app_config } from "../types.tsx"
import { app_key, api_key, dev_api_port, prod_api_port } from "../globals.tsx"

let baseURL:string
let appConfig:TAppConfig  = default_app_config

if (process.env.NODE_ENV === 'development') {
	baseURL = "https://localhost:" + dev_api_port + "/api"	
} else {	
	baseURL = "https://i4cm.com/api"
}

export function storeAppConfig(ac:TAppConfig) {
	appConfig = {...ac}
	if (ac.session_token !== '') {
		localStorage.setItem(app_key + '_config', JSON.stringify(ac))
	} else {
		localStorage.setItem(app_key + '_config', '')
	}
}

export function retrieveAppConfig() {
	if (appConfig.session_token === '') {
		const new_appConfig = localStorage.getItem(app_key + '_config');
		if (new_appConfig && new_appConfig !== '') {
			appConfig = JSON.parse(new_appConfig)
		}
	}
	return appConfig
}

export type goResult = {
	httpstatus: number,
	data: any
}
export type goResultError = {
	message:string
}

export async function goAPI(verb:string, route: string, data: any = null): Promise<goResult> {
	appConfig = retrieveAppConfig()

	try {
		console.log("baseURL: " + baseURL)
		const response = await fetch(baseURL + route, {
				method: verb,
				mode: 'cors',
				cache: 'no-cache',
				headers: {
						'Accept': '*/*',
						'Content-Type': 'application/json',
						Authorization: 'Bearer ' + appConfig.session_token,
						'XApiKey': api_key
				},
				body: verb !== 'GET' ? JSON.stringify(data) : null
		})
		const o = await response.json()
		return { httpstatus: response.status, data: o }
	} catch (error) {
		return { httpstatus: 500, data: "go:goPut():fetch() error: " + error }
	}
}

