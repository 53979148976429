import React, { useState, useEffect, useContext } from 'react'
import { Select, Stack, MenuItem } from '@mui/material'
import SECPanel from './SECPanel/SECPanel.tsx'

import './USMarkets.css'

export default function USMarkets(
    { appendStatusText }
    :{ appendStatusText: (text: string) => void }
  ) {
  
  const doLoadDashboard = () => {    
  }

  useEffect(() => {
    doLoadDashboard()
  }, [])

  return (
    <Stack direction='column'>
      <SECPanel appendStatusText={appendStatusText} />
    </Stack>
  )
}