import React, { useContext, useState } from 'react'
import { Box, Stack } from '@mui/material'
import { useLocation, Outlet, Navigate } from 'react-router-dom'
import Sidebar from './Sidebar.tsx'
import StatusPanel from './workspaces/StatusPanel.tsx'
import NotificationsPanel from './workspaces/NotificationsPanel.tsx'
import { TAppContext, AppContext } from './types.tsx'
import { sidebar_height, sidebar_width } from './globals.tsx'

export default function Layout(
    { 
      statusHistory, clearStatusHistory, notificationsHistory, clearNotificationsHistory 
    }
    :{ 
      statusHistory: string[], clearStatusHistory: () => void, notificationsHistory: string[], clearNotificationsHistory: () => void 
    }
  ) {
  const {config, setConfig}:TAppContext = useContext(AppContext)  
  const location = useLocation()  

  if (!config.in_session && location.pathname !== '/login') { 
    return <Navigate to="/login" replace />
  }
  if (config.in_session && location.pathname === '/login') { 
    return <Navigate to="/" replace />
  }
  if (location.pathname === '/login') {
    return(
      <>
        <div className="overlayed">
          <Sidebar/>
        </div>
        <Outlet />
      </>
    )
  }

  return (
    <div>
      <Stack 
        direction={{
          xs:'column', 
          sm:'column', 
          md:'row'
        }} 
        sx={{
          overflowY:'scroll', 
          height:{
            xs:'calc(100vh - ' + sidebar_height + ')', 
            sm:'calc(100vh - ' + sidebar_height + ')', 
            md:'100vh'
          }}
        }
      >
        <Box>
          <Sidebar/>
        </Box>
        <Box sx={{
          width:{
            xs:'100%', 
            sm:'100%', 
            md:'calc(100vw - ' + sidebar_width + ')'
          }, 
          height:{
            xs:'calc(100vh - ' + sidebar_height + ')', 
            sm:'calc(100vh - ' + sidebar_height + ')', 
            md:'100%'
          },
          padding: '5px',
          overflowY:'scroll'
        }}>
          <Stack direction="row">
            <StatusPanel 
              statusHistory={statusHistory} 
              clearStatusHistory={clearStatusHistory} 
            />
            <NotificationsPanel 
              notificationsHistory={notificationsHistory} 
              clearNotificationsHistory={clearNotificationsHistory} 
            />
          </Stack>
          <Outlet />
        </Box>
      </Stack>
    </div>
  )
}
