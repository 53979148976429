import React, {StrictMode, useState } from 'react'
import { HashRouter, Routes, Route } from 'react-router-dom'
import Login from './Login.tsx'
import Layout from './Layout.tsx'
import * as element from './workspaces/index.tsx'
import { AppContextProvider } from './AppContext.tsx'
import { getLocalTimeFromEpoch, getNowEpoch } from './utility/time.tsx'

import './App.css'

export default function App() {
  const [statusHistory, setStatusHistory] = useState<string[]>([])
  const [notificationsHistory, setNotificationsHistory] = useState<string[]>([])

  const appendStatusText = (text: string) => {
    setStatusHistory(o => {return [getLocalTimeFromEpoch(getNowEpoch()) + ': ' + text, ...o]})
  }
  const appendNotificationsText = (text: string) => {
    setNotificationsHistory(o => {return [getLocalTimeFromEpoch(getNowEpoch()) + ': ' + text, ...o]})
  }

  const clearStatusHistory = () => {
    setStatusHistory([])
  }
  const clearNotificationsHistory = () => {
    setStatusHistory([])
  }

  return (
    <StrictMode>
      <AppContextProvider>
        <HashRouter>
          <Routes>
            <Route element={
              <Layout 
                statusHistory={statusHistory} 
                clearStatusHistory={clearStatusHistory} 
                notificationsHistory={notificationsHistory} 
                clearNotificationsHistory={clearNotificationsHistory} 
              />
              }>
              <Route index element={
                <element.USMarkets appendStatusText={appendStatusText} />
                }
              />
              <Route path="account" element={(<element.Account appendStatusText={appendStatusText} />)}/>
              <Route path="login" element={(<Login/>)}/>
              <Route path="*" element={(<element.USMarkets appendStatusText={appendStatusText} />)}/>
            </Route>
          </Routes>
        </HashRouter>
      </AppContextProvider>
    </StrictMode>
  );
}