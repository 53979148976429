const dayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

export function getLocalDateFromEpoch(e: number): string {
	let date: Date = new Date(e)
    if (e > 0) {
	    return (date.getMonth() + 1) + '.' + date.getDate()
    } else {
        return "-"
    }
}

export function getLocalTimeFromEpoch(e: number): string {    
	let date: Date = new Date(e)
	return date.toLocaleDateString('en-US') + ' ' + date.toLocaleTimeString('en-US')
}

export function getNowEpoch(): number {
    const now = new Date();
	return Math.floor(now.getTime())
}

export const getDayOfWeekFromEpoch = (e) => {
	const d = new Date(e)
	var dow = d.getUTCDay()

	// Adjust from their mapping (0=Sunday) to ours (0=Monday)
	if (dow === 0) {
		dow = 6
	} else {
		dow -= 1
	}

	return dow
}

export function getStartOfDayEpoch(e: number):number {
    var date = new Date(e)
    date.setUTCHours(0, 0, 0, 0)
    return date.getTime()
}

export function isSameDay(e: number, q: number):boolean {
    const ee = getStartOfDayEpoch(e)
    const qq = getStartOfDayEpoch(q)
    return ee === qq
}

export function isEpochInSet(e: number, set: number[]):boolean {
    for (var i = 0; i < set.length; i++) {
        if (isSameDay(e, set[i])) {
            return true
        }
    }
    return false
}

export function getNewDateForDuration(e:number, d:number, holidays:number[]): number {
    e = getStartOfDayEpoch(e)
    while (d > 0) {
        const dow:number = getDayOfWeekFromEpoch(e)
        if (0 <= dow && dow <= 4 && !isEpochInSet(e, holidays)) {
            d -= 1
        }
        e += 24*60*60
    }
    return e
}

export function getDifferenceInBusinessDays(e: number, q: number, holidays:number[]):number {
    let ee = getStartOfDayEpoch(e), qq = getStartOfDayEpoch(q)
    let d = 0
    let reversed: boolean = false
    if (q < e) {
        e = qq
        q = ee
        reversed = true
    } else {
        e = ee
        q = qq  
    }
    while (!isSameDay(e, q)) {
        const dow:number = getDayOfWeekFromEpoch(e)
        if (0 <= dow && dow <= 4 && !isEpochInSet(e, holidays)) {
            d += 1
        }
        e += 24*60*60
    }
    if (reversed) {
        d *= -1
    }
    return d 
}

export function getLocalTimeOfDayFromEpoch(e:number, m:boolean = false, s:boolean = false) {    
    var date = new Date(e);

    // Extract local hours, minutes, and seconds
    var hours = date.getHours();
    var minutes = "0" + date.getUTCMinutes();
    var seconds = "0" + date.getUTCSeconds();

    // Format time in HH:MM:SS format using slice
    var formattedTime = (hours % 12) + (m ? ':' + minutes.slice(-2) + (s ? ':' + seconds.slice(-2) : '') : '') + ' ' + (hours > 12 ? 'PM' : 'AM')

    return formattedTime;
}

export function getNextLocalBusinessDayName() {
    // [TODO] This needs to change to deal with holidays [] and to follow the dow convention from other functions
    const today = new Date();
    const dayOfWeek = today.getDay(); // 0 is Sunday, 1 is Monday, ..., 6 is Saturday

    let daysToAdd;
    if (dayOfWeek === 5) { // If today is Friday
        daysToAdd = 3; // Skip to Monday
    } else if (dayOfWeek === 6) { // If today is Saturday
        daysToAdd = 2; // Skip to Monday
    } else { // Any other day
        daysToAdd = 1;
    }

    const nextBusinessDay = new Date(today.getFullYear(), today.getMonth(), today.getDate() + daysToAdd);    
    const nameOfNextBusinessDay = dayNames[nextBusinessDay.getDay()];

    return nameOfNextBusinessDay;
}

export function getDayOfWeekName(dow:number) {
    return dayNames[(dow+1) % 7]
}

export function getEpochForDate(d:Date) {
    return Math.floor(d.getTime())
}

export function normalizeEpoch(e:number) {
    // Create a Date object from the Unix timestamp
    const date = new Date(e)

    // Set time to 12 PM GMT
    date.setUTCHours(12, 0, 0, 0)

    // Convert back to Unix timestamp and return
    return Math.floor(date.getTime())
}

export function getEpochForCustomDate(d) {
    const currentMonth: number = new Date().getMonth() + 1
    const currentYear: number = new Date().getFullYear()
    const [month, day] = d.split('.').map(Number)
    const date = new Date(month > currentMonth ? currentYear - 1 : currentYear, month - 1, day)
    const e = Math.floor(date.getTime())
    return e    
}