import React from 'react'
import { Stack } from '@mui/material'
import { status_height } from "../globals.tsx"

import './StatusPanel.css'

export default function StatusPanel(
		{ statusHistory, clearStatusHistory }
		:{ statusHistory: string[], clearStatusHistory: () => void}
	) {

	let x:string[] = [...statusHistory]
	if (x.length > 0) {
		x[0] = "⮞ " + x[0]
	}
	let strings:string = x.join('\n')

	return (
		<Stack direction='row' sx={{ width: "40%" }}>
			<button className='statuspanel_button' onClick={() => { clearStatusHistory() }}>
				Clear Status History
			</button>
			<textarea 
				value={ strings } 
				style={{ 
					resize: 'both',
					width: '100%', 
					height: status_height, 
					backgroundColor: '#f2f2f2', 
					borderTop: '2px solid #aaa', 
					borderLeft: '2px solid #aaa',
					borderBottom: '2px solid #eee', 
					borderRight: '2px solid #eee',
					overflow: 'auto'
 				}}				
			/>
		</Stack>
	)
}