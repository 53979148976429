import React, { useContext, useEffect, useState, PureComponent, useRef } from 'react'
import { createRoot } from 'react-dom/client'
import { goAPI } from '../../../utility/go.tsx'
import { Stack } from '@mui/material'
import {
  Label,
  LineChart,
  Line,
  CartesianGrid,
	Legend,
  XAxis,
  YAxis,
  Tooltip,
  ReferenceArea,
  ResponsiveContainer,
} from 'recharts'
import { TDailyInsider, TDailyMarket, TInsiderDay, default_sec_watch } from '../../../types.tsx'
import { getDayOfWeekFromEpoch, getLocalDateFromEpoch, getNowEpoch } from '../../../utility/time.tsx'
import { position_colour, open_colour, close_colour, high_colour, low_colour, notes_width, apr_font_default, apr_background_default } from '../../../globals.tsx'
import { TSECWatch } from '../../../types.tsx'
import { TAppContext, AppContext } from '../../../types.tsx'
import { apr_font_highlight, apr_background_highlight } from '../../../globals.tsx'
import { equity_profile_chart_height } from '../../../globals.tsx'

import './EquityProfileChart.css'
import { mapRange } from '../../../utility/number.tsx'
import EquityDayDetail from './EquityDayDetail.tsx'
import { goResult } from '../../../utility/go.tsx'

type TSet = {
	name: string,
	open: number,
	close: number,
	high: number,
	low: number,
	volume: number,
	relative_insider_position: number
}

type TState = {
	data: TSet[],
	left: string,
	right: string,
	refAreaLeft: string,
	refAreaRight: string,
	top: string,
	bottom: string,
	top2: string,
	bottom2: string,
	animation: boolean
}

const initialState: TState = {
  data: [],
  left: 'dataMin',
  right: 'dataMax',
  refAreaLeft: '',
  refAreaRight: '',
  top: 'dataMax*1.2',
  bottom: 'dataMin*1.2',
  top2: 'dataMax*1.2',
  bottom2: 'dataMin*1.2',
  animation: true,
}
Object.freeze(initialState)

type TProps = {
	clearCache: (symbol: string) => void,
	closeChart: (symbol: string) => void,
	symbol: string,
	activeSymbol: string,
	changeActiveSymbol: any,
	issuername: string,
	daily_market: TDailyMarket,
	daily_insider: TDailyInsider,
	edges: number[],
	alpha_air_score: number,
	beta_air_score: number,
	gamma_air_score: number,
	alpha_adr_score: number,
	beta_adr_score: number,
	gamma_adr_score: number,
	isShortWatched: boolean,
	isLongWatched: boolean,
	toggleShortWatch: (symbol: string) => void,
	toggleLongWatch: (symbol: string) => void,
	appendStatusText: (text: string) => void,
	quarantineSymbol: (symbol: string) => void
}

class CustomizedYAxisLeftTick extends PureComponent {
  render() {
    const { x, y, payload } = this.props

    return (
      <g transform={`translate(${x},${y - 13})`}>
        <text x={0} y={0} dy={16} textAnchor="end" fill={open_colour} >
          ${payload.value.toFixed(2)}
        </text>
      </g>
		)
  }
}
class CustomizedYAxisRightTick extends PureComponent {
  render() {
    const { x, y, payload } = this.props
		const x_offset = 55
		let number_colour = '#44aaed'
		if (payload.value < 0) {
			number_colour = position_colour
		}

    return (
      <g transform={`translate(${x + x_offset}, ${y - 13})`}>
        <text x={0} y={0} dy={16} textAnchor="end" fill={number_colour} >
          ${Math.round(payload.value).toLocaleString()}
        </text>
      </g>
		)
  }
}

export default function EquityProfileChart({ 
		clearCache, 
		closeChart, 
		symbol, 
		activeSymbol,
		changeActiveSymbol,
		issuername, 
		daily_market, 
		daily_insider,
		edges,
		alpha_air_score,
		beta_air_score,
		gamma_air_score,
		alpha_adr_score,
		beta_adr_score,
		gamma_adr_score,
		isShortWatched,
		isLongWatched,
		toggleShortWatch,
		toggleLongWatch,
		appendStatusText,
		quarantineSymbol
		}: TProps) {
	const {config, setConfig}:TAppContext = useContext(AppContext)
	const [state, setState] = useState<TState>({...initialState})

	const popupWindowRef = useRef<Window | null>(null)	

	//let inspector_window:number = Object.keys(daily_insider).length;
	let inspector_window:number = 730*5

	const openFinvizPage = (symbol:string) => {
		changeActiveSymbol(symbol)
		window.open('https://finviz.com/quote.ashx?t=' + symbol + '&p=d',	'',	'')
	}
	const openYahooPage = (symbol:string) => {
		changeActiveSymbol(symbol)
		window.open('https://ca.finance.yahoo.com/quote/' + symbol + '/key-statistics/',	'',	'')
	}
	const openStockTwitsPage = (symbol:string) => {
		changeActiveSymbol(symbol)
		window.open('https://stocktwits.com/symbol/' + symbol,	'',	'')
	}

	class CustomizedXAxisTick extends PureComponent {
		openPopup(e:number) {
			let date = getLocalDateFromEpoch(e)
			const popupWindow = window.open('popup.html',	'',	'')
	
			if (popupWindow) {
				popupWindow.focus()
				popupWindow.document.title = symbol + ': ' + issuername + ' for ' + date				

				setTimeout(() => {
					const element = popupWindow.document.getElementById('popup_root')
					if (element) {
						const root = createRoot(element)
						root.render(
							<React.StrictMode>
								<EquityDayDetail 
									symbol={symbol} 
									issuername={issuername} 
									dow={e} 
									daily_market={daily_market} 
									daily_insider={daily_insider} 
								/>
							</React.StrictMode>
						)
					}
				}, 100)
			}
		}

		openNewsPopup(url:string) {
			const popupWindow = window.open(url,	'',	'')
		}		

		render() {
			const { x, y, payload } = this.props			

			let number_colour = '#000'
			if (payload.value < 0) {
				number_colour = '#f00'
			}
	
			let e = Number(payload.value) //getNowEpoch() - (inspector_window - payload.value) * 24 * 60 * 60 * 1000
			let day_of_week: number = getDayOfWeekFromEpoch(e)
			let date = getLocalDateFromEpoch(e)
			//let dow: number = payload.value
			let id: TInsiderDay = daily_insider[e]
			let clickable: boolean = false

			//console.log("[" + inspector_window + ", " + payload.value + "]")

			if (id && id.submissions) {
				if (id.submissions.length > 0) {
					number_colour = "#0e0"
					clickable = true
				} else if (id.nd_transactions.length > 0) {
					number_colour = "#f0f"
					clickable = true
				} else if (id.d_transactions.length > 0) {
					number_colour = "#00f"
					clickable = true
				}
			}

			//console.log("payload e: " + e + ", value: " + payload.value)
	
			return (
				<g transform={`translate(${x - 13}, ${y + 2})`}>
					<text 
						x={0} 
						y={0} 
						dy={16} 
						textAnchor="end" 
						fill={number_colour} 
						transform='rotate(-90)' 
						onClick={ () => { if (clickable) this.openPopup(e) }} 
						cursor={clickable ? "pointer" : "normal"}
					>
						<tspan 
							font-weight={day_of_week >= 5 ? "700" : "400"}
							font-size={9}
						>
							{edges.indexOf(e) != -1 ? '◉' : ''}{date}
						</tspan>
					</text>
					<text
						x={16} 
						y={25} 
						dy={16} 
						textAnchor="end" 
						fill={number_colour} 
						onClick={ () => { if (clickable) this.openPopup(e) }} 
						cursor={clickable ? "pointer" : "normal"}
					>
						A
					</text>
					<text
						x={16} 
						y={35} 
						dy={16} 
						textAnchor="end" 
						fill={number_colour} 
						onClick={ () => { if (clickable) this.openNewsPopup('https://www.insidermonkey.com/blog/clover-health-investments-corp-clov-a-hot-penny-stock-on-the-move-1345471/') }} 
						onMouseEnter={ () => {} }
						onMouseLeave={ () => {} }
						cursor={clickable ? "pointer" : "normal"}
					>
						B
					</text>
				</g>
			)
		}
	}	

	const getAxisYDomain = (fromX, toX, dataKey) => {
		const refData = state.data.slice(fromX - 1, toX)
		let [bottom, top] = [refData[0][dataKey], refData[0][dataKey]]
		refData.forEach((d) => {
			if (d[dataKey] > top) top = d[dataKey]
			if (d[dataKey] < bottom) bottom = d[dataKey]
		})

		const offset: number = 0
		return [(bottom | 0) - offset, (top | 0) + offset]
	}

  const clearTickerCaches = (symbol: string) => {
		//cleartickercache
		clearCache(symbol)
		closeChart(symbol)
	}	 

	const getMaxTicks = ():number => {
		let dates: string[] = Object.keys(daily_insider)
		return dates.length
		/*
		let dates: string[] = Object.keys(daily_insider)
		if (dates.length > 0) {
			if (dates.length < inspector_window) {
				return inspector_window + 0 // - daily_market[dates[0]].dow + 1
			} else {
				return inspector_window + 0
			}
		}
		return 0
		*/
	}

	const addToQuarantine = (symbol:string) => {
		quarantineSymbol(symbol)
		closeChart(symbol)		
	}

	useEffect(() => {
		let new_state = {...state}
		let dates: string[] = Object.keys(daily_insider)
		new_state.data = new Array(dates.length)
		for (let i = 0; i < dates.length; i++) {
			if (daily_market[dates[i]]) {
				new_state.data[i] = { 
					name: dates[i], 
					open: daily_market[dates[i]].open, 
					close: daily_market[dates[i]].close, 
					high: daily_market[dates[i]].high, 
					low: daily_market[dates[i]].low, 
					volume: daily_market[dates[i]].volume, 
					relative_insider_position: daily_insider[dates[i]] ? Math.trunc(daily_insider[dates[i]].position) : 0
				}
			} else {
				new_state.data[i] = {
					name: dates[i],
					open: null,
					close: null,
					high: null,
					low: null,
					volume: null,
					relative_insider_position: null
				}
			}
		}
		setState(new_state)
	}, [daily_insider, daily_market, activeSymbol])

	const { data, left, right, refAreaLeft, refAreaRight, top, bottom, top2, bottom2 } = state

	const gap: number = 0 //-mapRange(window.innerWidth, 0, 2560, 0, -15)
	//console.log("gap = " + gap + ", innerWidth = " + window.innerWidth)

	var device_width = window.screen.width - notes_width - 120

	/*
<button 
					type="button" 
					onClick={ () => toggleShortWatch(symbol) } 
					className='equityprofilechart_mini_button' 
				>
					{
						isShortWatched ? 
							"Remove from Holdings"
							: "Add to Holdings"
					}
				</button>
				<button 
					type="button" 
					onClick={ () => toggleLongWatch(symbol) } 
					className='equityprofilechart_mini_button' 
				>
					{
						isLongWatched ? 
							"Remove from Watches"
							: "Add to Watches"
					}
				</button>
				<button 
					type="button" 
					onClick={ () => addToQuarantine(symbol) } 
					className='equityprofilechart_mini_button' 
				>
					Quarantine
				</button>
	*/

	return (
		<div 
			className="highlight-bar-charts" 
			style={{ 
				userSelect: 'none', 
				width: device_width, 
				border: '1px solid #ccc', 
				padding: 10 
			}}
		>
			<Stack direction='row' style={{ paddingLeft: 40, paddingRight: 40 }}>				
				<button 
					type="button" 
					onClick={ () => toggleLongWatch(symbol) } 
					className='equityprofilechart_mini_button' 
				>
					{
						isLongWatched ? 
							"Remove from Watches"
							: "Add to Watches"
					}
				</button>				
				
				<div style={{ flex: 1, textAlign: 'center' }}>					
					<h5>
						<button
							onClick={ () => changeActiveSymbol(symbol) }
							style={{
								border:'solid 1px', 
								borderColor:'#ccc #000 #000 #ccc',
								color: activeSymbol === symbol ? apr_font_highlight : apr_font_default, 
								backgroundColor: activeSymbol === symbol ? apr_background_highlight : apr_background_default,
							}}
						>
							{symbol}: {issuername} [AIR: {(alpha_air_score * 100).toFixed(2)}%, {(beta_air_score * 100).toFixed(2)}%, {(gamma_air_score * 100).toFixed(2)}% / {edges.length}, 
							ADR: {(alpha_adr_score * 100).toFixed(2)}%, {(beta_adr_score * 100).toFixed(2)}%, {(gamma_adr_score * 100).toFixed(2)}% / {edges.length}]
						</button>
						<button 
							onClick={() => { openFinvizPage(symbol) }}
							style={{
								padding: 2, 
								textAlign:'center', 
								border:'solid 1px', 
								borderColor:'#ccc #000 #000 #ccc',
								color: activeSymbol === symbol ? apr_font_highlight : apr_font_default, 
								backgroundColor: activeSymbol === symbol ? apr_background_highlight : apr_background_default,
								marginLeft: 5,
								marginRight: 3, 
								marginBottom: 2 
							}}
						>
							fv
						</button>
						<button 
							onClick={() => { openYahooPage(symbol) }}
							style={{
								padding: 2, 
								textAlign:'center', 
								border:'solid 1px', 
								borderColor:'#ccc #000 #000 #ccc',
								color: activeSymbol === symbol ? apr_font_highlight : apr_font_default, 
								backgroundColor: activeSymbol === symbol ? apr_background_highlight : apr_background_default,
								marginRight: 3, 
								marginBottom: 2 
							}}
						>
							y!
						</button>
						<button 
							onClick={() => { openStockTwitsPage(symbol) }}
							style={{
								padding: 2, 
								textAlign:'center', 
								border:'solid 1px', 
								borderColor:'#ccc #000 #000 #ccc',
								color: activeSymbol === symbol ? apr_font_highlight : apr_font_default, 
								backgroundColor: activeSymbol === symbol ? apr_background_highlight : apr_background_default,
								marginRight: 3, 
								marginBottom: 2 
							}}
						>
							💬
						</button>
					</h5>
				</div>
				<button 
					type="button" 
					onClick={ () => closeChart(symbol) } 
					className='equityprofilechart_mini_button'
				>
					Close
				</button>
			</Stack>

			<div style={{ overflowX: "scroll", width: "100%" }}>
				<ResponsiveContainer width={inspector_window*5} height={equity_profile_chart_height}>
					<LineChart
						height={equity_profile_chart_height}
						data={data}	
					>
						<CartesianGrid strokeDasharray="2 2" />
						<XAxis 

							dataKey="name" 
							domain={[left, right]} 
							type="category" 
							tickCount={getMaxTicks()}
							tick={<CustomizedXAxisTick />}
							tickMargin={-1}
							tickSize={3}
							minTickGap={-200}
							height={60}
						/>
						<YAxis 
							allowDataOverflow 
							domain={[bottom, top]} 
							type="number" 
							yAxisId="1" 
							tick={<CustomizedYAxisLeftTick />} 
							tickCount={50}
						/>
						<YAxis 
							orientation="right" 
							allowDataOverflow 
							domain={[bottom2, top2]} 
							type="number" 
							yAxisId="2" 
							tick={<CustomizedYAxisRightTick />} 
							tickCount={100}
						/>
						<Legend 
							verticalAlign="top" 
							height={0} 
						/>
						<Line 
							yAxisId="1" 
							type="linear" 
							dataKey="open" 
							stroke={open_colour}
							animationDuration={200}
							connectNulls
						/>					
						<Line 
							yAxisId="1" 
							type="linear" 
							dataKey="close" 
							stroke={close_colour}
							animationDuration={200}
							connectNulls
						/>					
						<Line 
							yAxisId="1" 
							type="linear" 
							dataKey="high" 
							stroke={high_colour}
							animationDuration={200}
							connectNulls
						/>
						<Line 
							yAxisId="1" 
							type="linear" 
							dataKey="low" 
							stroke={low_colour}
							animationDuration={200}
							connectNulls
						/>
						<Line 
							yAxisId="2" 
							type="linear"
							dataKey="relative_insider_position" 
							stroke={position_colour}
							animationDuration={200} 
							connectNulls
						/>					
						<Tooltip />

						{
							refAreaLeft && refAreaRight ? 
								<ReferenceArea yAxisId="1" x1={refAreaLeft} x2={refAreaRight} strokeOpacity={0.3} />
								: <></>
						}
					</LineChart>
				</ResponsiveContainer>
			</div>
		</div>
	)
}
