import * as React from 'react-dom'
import { Box, Typography } from '@mui/material'
import './VerticalSelection.css'

export default function VerticalSelection({ selectedCategory }: { selectedCategory: string}) {
  const sc = selectedCategory;
  const allcaps = sc.toUpperCase();

  return (
    <Box>
      <div className="vertical_category_selection">
        <Typography variant="h3" letterSpacing={-1} fontFamily="Roboto" sx={{mt:0, color:'#eee'}} className="company_initials">
          {allcaps}
        </Typography>
      </div>
    </Box>
  );
}
