import React, { useState, useEffect, useContext } from "react"
import { Select, Stack, MenuItem, easing } from '@mui/material'
import { goAPI, goResult } from "../../../utility/go.tsx"
import { TAppContext, AppContext, TAppConfig } from '../../../types.tsx'
import Inspector from "./Inspector.tsx"

export default function SECPanel (
		{ appendStatusText }
		:{ appendStatusText: (text: string) => void }
	) {
	const {config, setConfig}:TAppContext = useContext(AppContext)	
	const [showShorts, setShowShorts] = useState<boolean>(true)
	const [showLongs, setShowLongs] = useState<boolean>(true)
	const [showAllAPRs, setShowAllAPRs] = useState<boolean>(true)

	// Utility functions -----------------------------------------------------
	const handleRecalculateAllAPRs = () => {
		goAPI("PUT", "/sec/recalculateallaprs?configuration_id=1").then((go: goResult) => {
			if (go.httpstatus === 200) {
        const log: string[] = go.data.log
				log.forEach(item => {
					appendStatusText(item)
				})				
      } else if (go.httpstatus === 401) {
        setConfig({...config, in_session: false})
			} else {
        console.log("Unexpected HTTP status: [" + go.httpstatus + ", " + JSON.stringify(go.data) + "]")
      }
		})
	}
	const handleGetJobStatus = () => {
		goAPI("GET", "/sec/jobstatus").then((go: goResult) => {
			if (go.httpstatus === 200) {
        const log: string[] = go.data.log
				log.forEach(item => {
					appendStatusText(item)
				})
      } else if (go.httpstatus === 401) {
        setConfig({...config, in_session: false})
			} else {
        console.log("Unexpected HTTP status: [" + go.httpstatus + ", " + JSON.stringify(go.data) + "]")
      }
		})
	}
	const handlePauseJobs = () => {
		goAPI("PUT", "/sec/pausejobs").then((go: goResult) => {
			if (go.httpstatus === 200) {
        const log: string[] = go.data.log
				log.forEach(item => {
					appendStatusText(item)
				})				
      } else if (go.httpstatus === 401) {
        setConfig({...config, in_session: false})
			} else {
        console.log("Unexpected HTTP status: [" + go.httpstatus + ", " + JSON.stringify(go.data) + "]")
      }
		})
	}

	const handleToggleShorts = () => {		
		setShowShorts(!showShorts)
	}
	const handleToggleLongs = () => {		
		setShowLongs(!showLongs)
	}
	const handleToggleTopAPRs = () => {		
		setShowAllAPRs(!showAllAPRs)
	}

	const handleNewAPRScore = (score:number) => {

	}

	useEffect(() => {		
	}, [])

	/*
					<button onClick={() => { handleRecalculateAllAPRs() }} style={{ marginRight: 2 }}>
						Recalculate All APRs...
					</button>
	*/

	/*
<Stack direction="row">
					<button onClick={() => { handleGetJobStatus() }} style={{ marginRight: 2 }}>
						Get Job Status
					</button>

					<button onClick={() => { handleToggleShorts() }} style={{ marginRight: 2 }}>
						{ showShorts ? "Hide Holdings" : "Show Holdings" }
					</button>
					<button onClick={() => { handleToggleLongs() }} style={{ marginRight: 2 }}>
					{ showLongs ? "Hide Watches" : "Show Watches" }
					</button>
					<button onClick={() => { handleToggleTopAPRs() }} style={{ marginRight: 2 }}>
					{ showAllAPRs ? "Hide Untiered APRs" : "Show All TopAPRs" }
					</button>
					<button onClick={() => { handlePauseJobs() }} style={{ marginRight: 2 }}>
						Pause Jobs
					</button>
				</Stack>
	*/

	return (
		<div style={{ width: '100%', backgroundColor: '#fff' }}>
			<h3>
				USA: NYSE/NASDAQ
			</h3>			
			<div>
				<h4>
					Functions
				</h4>
				<Stack direction="row">
					<button onClick={() => { handleGetJobStatus() }} style={{ marginRight: 2 }}>
						Get Job Status
					</button>

					<button onClick={() => { handleToggleShorts() }} style={{ marginRight: 2 }}>
						{ showShorts ? "Hide Holdings" : "Show Holdings" }
					</button>
					<button onClick={() => { handleToggleLongs() }} style={{ marginRight: 2 }}>
					{ showLongs ? "Hide Watches" : "Show Watches" }
					</button>
					<button onClick={() => { handleToggleTopAPRs() }} style={{ marginRight: 2 }}>
					{ showAllAPRs ? "Hide Untiered APRs" : "Show All TopAPRs" }
					</button>
					<button onClick={() => { handlePauseJobs() }} style={{ marginRight: 2 }}>
						Pause Jobs
					</button>
				</Stack>


				<Inspector 
					appendStatusText = { appendStatusText } 
					showShorts = { showShorts }
					showLongs = { showLongs }
					showAllAPRs = { showAllAPRs }
				/>
			</div>
		</div>		
	)
}