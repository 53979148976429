import React, { useState, useEffect, useContext, useRef } from "react"
import { Stack, Box } from '@mui/material'
import { goAPI, goResult } from "../../../utility/go.tsx"
import { 
	TAppContext, AppContext, 
	TDailyMarket, TDailyInsider, default_sec_watch, TAPRData } from '../../../types.tsx'
import { getEpochForDate, getLocalDateFromEpoch, getNowEpoch } from "../../../utility/time.tsx"
import EquityProfileChart from "./EquityProfileChart.tsx"
import { TSECWatch } from '../../../types.tsx'
import { apr_font_default, apr_font_highlight, apr_font_dimmed } from "../../../globals.tsx"
import { apr_background_default, apr_background_highlight } from "../../../globals.tsx"
import { tier_img_width, tier_img_height } from "../../../globals.tsx"
import SmartTickerButton from "./Inspector/SmartTickerButton.tsx"

import "react-datepicker/dist/react-datepicker.css"
import SECNotes from "./SECNotes.tsx"

let debounce_epoch: number = 0
const debounce_interval: number = 1000
//const initial_chart_areas: TEquityData[] = new Array(max_charts).fill(null)

type TProps = {
	appendStatusText: (s:string) => void,
	showShorts: boolean,
	showLongs: boolean,
	showAllAPRs: boolean,
}

export default function Inspector({ appendStatusText, showShorts, showLongs, showAllAPRs }:TProps) {
	const {config, setConfig}:TAppContext = useContext(AppContext)
	const filterRef = useRef<HTMLInputElement>(null)
	const [tickers, setTickers] = useState<string[]>([])	
	//const [chartData, setChartData] = useState<TEquityData[]>([])
	const [dailyMarket, setDailyMarket] = useState<TDailyMarket[]>([])
	const [dailyInsider, setDailyInsider] = useState<TDailyInsider[]>([])
	const [symbols, setSymbols] = useState<string[]>([])
	const [issuerNames, setIssuerNames] = useState<string[]>([])
	const [toDate, setToDate] = useState<number>(getNowEpoch())
	const [SECShortWatches, setSECShortWatches] = useState<TSECWatch[]>([])
	const [SECLongWatches, setSECLongWatches] = useState<TSECWatch[]>([])
	const [refreshWatches, setRefreshWatches] = useState<number>(0)
	const [activeSymbol, setActiveSymbol] = useState<string>("")

	const [aprDOWEdges, setAPRDOWEdges] = useState<number[][]>([])

	const [alphaAIRScore, setAlphaAIRScore] = useState<number[]>([])
	const [betaAIRScore, setBetaAIRScore] = useState<number[]>([])
	const [gammaAIRScore, setGammaAIRScore] = useState<number[]>([])
	const [alphaADRScore, setAlphaADRScore] = useState<number[]>([])
	const [betaADRScore, setBetaADRScore] = useState<number[]>([])
	const [gammaADRScore, setGammaADRScore] = useState<number[]>([])

	const [alphaAIRScores, setAlphaAIRScores] = useState<TAPRData[]>([])
	const [betaAIRScores, setBetaAIRScores] = useState<TAPRData[]>([])
	const [gammaAIRScores, setGammaAIRScores] = useState<TAPRData[]>([])
	const [alphaADRScores, setAlphaADRScores] = useState<TAPRData[]>([])
	const [betaADRScores, setBetaADRScores] = useState<TAPRData[]>([])
	const [gammaADRScores, setGammaADRScores] = useState<TAPRData[]>([])
	
	const doClearCache = (symbol: string) => {
		if (symbol === "") {
			appendStatusText("Could not clear cache - no ticker selected.")
		} else {
			goAPI("PUT", `/sec/cleartickercache?symbol=${symbol}`).then((go: goResult) => {
				if (go.httpstatus === 200) {
					const data:{ log: string[], tickers: string[] } = go.data
					data.log.forEach(item => {
						appendStatusText(item)
					})
				} else if (go.httpstatus === 401) {
					setConfig({...config, in_session: false})
				} else {
					console.log("Unexpected HTTP status: [" + go.httpstatus + ", " + JSON.stringify(go.data) + "]")
				}
			})
		}
	}

	const doLoadTickers = (filter: string) => {
		appendStatusText("Loading tickers filtered on: " + filter)
		goAPI("GET", "/sec/tickers?filter=" + filter).then((go: goResult) => {
			if (go.httpstatus === 200) {
				const data:{ log: string[], tickers: string[] } = go.data
				data.log.forEach(item => {
					appendStatusText(item)
				})
        setTickers(data.tickers)
      } else if (go.httpstatus === 401) {
        setConfig({...config, in_session: false})
			} else {
        console.log("Unexpected HTTP status: [" + go.httpstatus + ", " + JSON.stringify(go.data) + "]")
      }
		})
	}

	const handleTickerEditChangeTimer = () => {
		if (!filterRef || !filterRef.current) {
			return
		}

		const now: number = getNowEpoch()
		if (now >= (debounce_epoch + debounce_interval)) {
			if (filterRef.current.value.length >= 2) {
				// Set debounce_epoch to tomorrow so that we block any further doLoadTicker() calls until we're done
				debounce_epoch = now + 24 * 60 * 60 * 1000
				doLoadTickers(filterRef.current.value)
				debounce_epoch = getNowEpoch()
			} else {
				// Do nothing - this firing can be safely ignored (d/t not enough characters to initiate a search)
			}
		} else {
			// Do nothing - this firing can be safely ignored (d/t a subsequent character was typed before first timer expired)
		}
	}	

	const handleTickerEditChange = () => {
		if (!filterRef || !filterRef.current) {
			return
		}

		if (filterRef.current.value.length >= 2) {
			debounce_epoch = getNowEpoch()
			setTimeout(handleTickerEditChangeTimer, debounce_interval)
		}
	}

	const onMenuSelection = (symbol:string, item:string) => {
		changeActiveSymbol(symbol)
		if (item === "Finviz") {
			window.open('https://finviz.com/quote.ashx?t=' + symbol + '&p=d',	'',	'')
		} else if (item === "Yahoo!") {
			window.open('https://ca.finance.yahoo.com/quote/' + symbol + '/key-statistics/',	'',	'')
		} else if (item === "Stocktwits") {
			window.open('https://stocktwits.com/symbol/' + symbol,	'',	'')
		}
	}

	const handleTickerSelected = (symbol: string) => {
		appendStatusText("Finding equity data for: " + symbol)

		setActiveSymbol(symbol)

		goAPI("GET", "/sec/dailyactivity?symbol=" + symbol).then((go: goResult) => {
			if (go.httpstatus === 200) {
				const data:{ 
					log: string[], 
					daily_market: TDailyMarket, 
					daily_insider: TDailyInsider, 
					issuername: string, 
					edges: number[],
					alpha_air_score: number,
					beta_air_score: number,
					gamma_air_score: number,
					alpha_adr_score: number,
					beta_adr_score: number,
					gamma_adr_score: number
				} = go.data
				data.log.forEach(item => {
					appendStatusText(item)
				})
				let newDailyMarket: TDailyMarket[] = [...dailyMarket, data.daily_market]
				let newDailyInsider: TDailyInsider[] = [...dailyInsider, data.daily_insider]
				let newAPRDOWEdges: number[][] = [...aprDOWEdges, data.edges]

				let newAlphaAIRScore: number[] = [...alphaAIRScore, data.alpha_air_score]
				let newBetaAIRScore: number[] = [...betaAIRScore, data.beta_air_score]
				let newGammaAIRScore: number[] = [...gammaAIRScore, data.gamma_air_score]
				let newAlphaADRScore: number[] = [...alphaADRScore, data.alpha_adr_score]
				let newBetaADRScore: number[] = [...betaADRScore, data.beta_adr_score]
				let newGammaADRScore: number[] = [...gammaADRScore, data.gamma_adr_score]
				
				setSymbols([...symbols, symbol])
				setIssuerNames([...issuerNames, data.issuername])
				setDailyMarket([...newDailyMarket])
				setDailyInsider([...newDailyInsider])
				setAPRDOWEdges([...newAPRDOWEdges])

				setAlphaAIRScore([...newAlphaAIRScore])
				setBetaAIRScore([...newBetaAIRScore])
				setGammaAIRScore([...newGammaAIRScore])

				setAlphaADRScore([...newAlphaADRScore])
				setBetaADRScore([...newBetaADRScore])
				setGammaADRScore([...newGammaADRScore])

				//doGetTopAPRs()
      } else if (go.httpstatus === 401) {
        setConfig({...config, in_session: false})
			} else {
        console.log("Unexpected HTTP status: [" + go.httpstatus + ", " + JSON.stringify(go.data) + "]")
      }
		})
	}

	const doCloseChart = (symbol: string) => {
		let newSymbols: string[] = [...symbols]
		let newIssuerNames: string[] = [...issuerNames]
		let newDailyMarket: TDailyMarket[] = [...dailyMarket]
		let newDailyInsider: TDailyInsider[] = [...dailyInsider]
		let newAPRDOWEdges: number[][] = [...aprDOWEdges]
		let newAlphaAIRScore: number[] = [...alphaAIRScore]
		let newBetaAIRScore: number[] = [...betaAIRScore]
		let newGammaAIRScore: number[] = [...gammaAIRScore]
		let newAlphaADRScore: number[] = [...alphaADRScore]
		let newBetaADRScore: number[] = [...betaADRScore]
		let newGammaADRScore: number[] = [...gammaADRScore]

		for (let i=0; i<symbols.length; i++) {
			if (symbols[i] === symbol) {
				newSymbols.splice(i, 1)
				newIssuerNames.splice(i, 1)
				newDailyMarket.splice(i, 1)
				newDailyInsider.splice(i, 1)
				newAPRDOWEdges.splice(i, 1)

				newAlphaAIRScore.splice(i, 1)
				newBetaAIRScore.splice(i, 1)
				newGammaAIRScore.splice(i, 1)

				newAlphaADRScore.splice(i, 1)
				newBetaADRScore.splice(i, 1)
				newGammaADRScore.splice(i, 1)

				break
			}
		}

		setSymbols([...newSymbols])
		setIssuerNames([...newIssuerNames])
		setDailyMarket([...newDailyMarket])
		setDailyInsider([...newDailyInsider])
		setAPRDOWEdges([...newAPRDOWEdges])
		setAlphaAIRScore([...newAlphaAIRScore])
		setBetaAIRScore([...newBetaAIRScore])
		setGammaAIRScore([...newGammaAIRScore])
		setAlphaADRScore([...newAlphaADRScore])
		setBetaADRScore([...newBetaADRScore])
		setGammaADRScore([...newGammaADRScore])
	}

	const doGetSECWatches = () => {
		goAPI("GET", "/secwatch/all").then((go: goResult) => {
			if (go.httpstatus === 200) {
        const log: string[] = go.data.log
				log.forEach(item => {
					appendStatusText(item)
				})

				let all_watches:TSECWatch[] = go.data.watches
				let short_watches:TSECWatch[] = []
				let long_watches:TSECWatch[] = []
				all_watches.forEach(w => {
					if (w.type == 0) {
						short_watches.push(w)
					} else if (w.type == 1) {
						long_watches.push(w)
					}
				})

				setSECShortWatches([...short_watches])
				setSECLongWatches([...long_watches])
      } else if (go.httpstatus === 401) {
        setConfig({...config, in_session: false})
			} else {
        console.log("Unexpected HTTP status: [" + go.httpstatus + ", " + JSON.stringify(go.data) + "]")
      }
		})		
	}	
	const doGetTopAPRs = () => {
		goAPI("GET", "/sec/topaprs").then((go: goResult) => {
			if (go.httpstatus === 200) {
        const log: string[] = go.data.log
				log.forEach(item => {
					appendStatusText(item)
				})
			
				let new_apr_scores:TAPRData[] = go.data.apr10_scores
				let new_apr30_scores:TAPRData[] = go.data.apr30_scores
				let new_apr90_scores:TAPRData[] = go.data.apr90_scores

				setAPRScores([...new_apr_scores])
				setAPR30Scores([...new_apr30_scores])
				setAPR90Scores([...new_apr90_scores])
      } else if (go.httpstatus === 401) {
        setConfig({...config, in_session: false})
			} else {
        console.log("Unexpected HTTP status: [" + go.httpstatus + ", " + JSON.stringify(go.data) + "]")
      }
		})				
	}

	const handleNewAPR = (symbol:string, score:number) => {

	}

	const getIsShortWatched = (symbol:string):boolean => {
		for (let i = 0; i < SECShortWatches.length; i++) {
			let w:TSECWatch = SECShortWatches[i]
			if ((w.type == 0 || w.type == 2) && w.keyword == symbol) {
				return true
			}
		}
		return false
	}
	const getIsLongWatched = (symbol:string):boolean => {
		for (let i = 0; i < SECLongWatches.length; i++) {
			let w:TSECWatch = SECLongWatches[i]
			if ((w.type == 1 || w.type == 2) && w.keyword == symbol) {
				return true
			}
		}
		return false
	}	

	const findWatchInsertIndex = (watches:TSECWatch[], watch:TSECWatch):number => {
    let left = 0;
    let right = watches.length;

    while (left < right) {
        const mid = Math.floor((left + right) / 2);
        if (watches[mid].keyword < watch.keyword) {
            left = mid + 1;
        } else {
            right = mid;
        }
    }
    return left;
	}
	const toggleShortWatch = (symbol:string):void => {
		let new_short_list:TSECWatch[] = [...SECShortWatches]

		for (let i = 0; i < SECShortWatches.length; i++) {
			let w:TSECWatch = SECShortWatches[i]
			if (w.keyword == symbol) {
				if (w.type == 0 || w.type == 2) {
					// remove from list
					new_short_list.splice(i, 1)
					setSECShortWatches([...new_short_list])

					// [TODO] call API to remove short list symbol
					goAPI("DELETE", "/secwatch/delete?symbol=" + symbol + "&type=0").then((go: goResult) => {
						if (go.httpstatus === 200) {
							const log: string[] = go.data.log
							log.forEach(item => {
								appendStatusText(item)
							})
						}
					})
					return
				}
			}
		}
		// add to short list
		let w:TSECWatch = {...default_sec_watch}
		w.keyword = symbol
		w.type = 0
		let i:number = findWatchInsertIndex(new_short_list, w)
		new_short_list.splice(i, 0, w)
		setSECShortWatches([...new_short_list])

		// [TODO] call API to add short list symbol
		goAPI("PUT", "/secwatch/create?symbol=" + symbol + "&type=0").then((go: goResult) => {
			if (go.httpstatus === 200) {
				const log: string[] = go.data.log
				log.forEach(item => {
					appendStatusText(item)
				})
			}
		})		
	}
	const toggleLongWatch = (symbol:string):void => {
		let new_long_list:TSECWatch[] = [...SECLongWatches]

		for (let i = 0; i < SECLongWatches.length; i++) {
			let w:TSECWatch = SECLongWatches[i]
			if (w.keyword == symbol) {
				if (w.type == 1 || w.type == 2) {
					// remove from list
					new_long_list.splice(i, 1)
					setSECLongWatches([...new_long_list])

					// [TODO] call API to remove long list symbol
					goAPI("DELETE", "/secwatch/delete?symbol=" + symbol + "&type=1").then((go: goResult) => {
						if (go.httpstatus === 200) {
							const log: string[] = go.data.log
							log.forEach(item => {
								appendStatusText(item)
							})
						}
					})
					return
				}
			}
		}
		// add to long list
		let w:TSECWatch = {...default_sec_watch}
		w.keyword = symbol
		w.type = 1
		let i:number = findWatchInsertIndex(new_long_list, w)
		new_long_list.splice(i, 0, w)
		setSECLongWatches([...new_long_list])

		// [TODO] call API to add long list symbol
		goAPI("PUT", "/secwatch/create?symbol=" + symbol + "&type=1").then((go: goResult) => {
			if (go.httpstatus === 200) {
				const log: string[] = go.data.log
				log.forEach(item => {
					appendStatusText(item)
				})
			}
		})
	}

	const renderShortsList = () => {
		return (
			<Stack direction="row">
				<div style={{ paddingTop: '3px', width: '75px' }}>
					Holdings: 
				</div>
				<Stack direction="row" sx={{ width:"96%", flexWrap:'wrap' }}>
						{
							SECShortWatches.map((w: TSECWatch) => {
								if (w.type == 0) {
									let sep:boolean = false
									if (short_symbol != w.keyword[0]) {
										sep = true
									} else {
										sep = false
									}
									short_symbol = w.keyword[0]
									return <div>
											<span style={{ margin: (sep ? "0 2px 0 5px" : "") }}>
												{ sep ? short_symbol + ":" : "" }
											</span>
											<SmartTickerButton symbol={w.keyword} activeSymbol={activeSymbol} onClicked={handleTickerSelected}  />
										</div>
								} else {
									return <></>
								}
							})
						}
				</Stack>
			</Stack>			
		)
	}

	const renderLongsList = () => {
		return (
			<Stack direction="row">
				<div style={{ width: '75px' }}>
					Watches: 
				</div>
				{
					SECLongWatches.map((w: TSECWatch) => {
						if (w.type == 1) {
							let sep:boolean = false
							if (long_symbol != w.keyword[0]) {
								sep = true
							} else {
								sep = false
							}
							long_symbol = w.keyword[0]

							return <>
								<span style={{ margin: (sep ? "0 2px 0 5px" : "") }}>{ sep ? long_symbol + ":" : "" }</span>
								<SmartTickerButton symbol={w.keyword} activeSymbol={activeSymbol} onClicked={handleTickerSelected} onMenuSelection={onMenuSelection}  />
							</>
						} else {
							return <></>
						}
					})
				}
			</Stack>			
		)
	}

	const getTierIcon = (tier:number) => {
		if (tier == 3) {
			return <img src="/images/gold.png" width={tier_img_width} height={tier_img_height} style={{ margin: '-2px 0 2px 2px' }}></img>
		} else if (tier == 2) {
			return <img src="/images/silver.png" width={tier_img_width} height={tier_img_height} style={{ margin: '-2px 0 2px 2px' }}></img>
		} else if (tier == 1) {
			return <img src="/images/bronze.png" width={tier_img_width} height={tier_img_height} style={{ margin: '-2px 0 2px 2px' }}></img>
		} else if (tier == 4) {
			return <img src="/images/platinum.png" width={tier_img_width} height={tier_img_height} style={{ margin: '-2px 0 2px 2px' }}></img>
		}
		return <></>
	}

	const renderAPRButton = (apr:TAPRData, score:number) => {
		if (showAllAPRs || (!showAllAPRs && apr.tier != 0)) {
			return <div>
				<button 
					onClick={(e) => { handleTickerSelected(e.currentTarget.value) }} 
					value={apr.symbol} 
					title={"c: " + getLocalDateFromEpoch(apr.compute_date) + ", i: " + getLocalDateFromEpoch(apr.inspection_date)}
					style={{ 
						padding: 2, 
						textAlign:'center', 
						border:'solid', 
						borderWidth:1, 
						borderColor:'#ccc #000 #000 #ccc', 
						color: activeSymbol == apr.symbol ? apr_font_highlight : apr.compute_date == apr.inspection_date ? apr_font_default : apr_font_dimmed, 
						backgroundColor: activeSymbol == apr.symbol ? apr_background_highlight : apr_background_default,
						marginRight: 3, 
						marginBottom: 2 
					}}
					key={apr.symbol}
				>
					{ apr.symbol }: { (score * 100).toFixed(2) } / { apr.degree } { getTierIcon(apr.tier) }
				</button>
			</div>
		} else {
			return <></>
		}		
	}

	const renderAPRsList = (all:boolean) => {
		return (
			<>
				<Stack direction="row">
					<div style={{ paddingTop: '3px', width: '75px' }}>
						Top APR10s: 
					</div>
					<Stack direction="row" sx={{ width:"96%", flexWrap:'wrap', marginLeft: 1 }}>
						{ APRScores.map((apr: TAPRData) => { return renderAPRButton(apr, apr.score_alpha) }) }
					</Stack>
				</Stack>
				<Stack direction="row">
				<div style={{ paddingTop: '3px', width: '75px' }}>
					Top APR30s: 
				</div>
				<Stack direction="row" sx={{ width:"96%", flexWrap:'wrap', marginLeft: 1 }}>
						{ APR30Scores.map((apr:TAPRData) => { return renderAPRButton(apr, apr.score_beta) }) }
				</Stack>
			</Stack>
			<Stack direction="row">
				<div style={{ paddingTop: '3px', width: '75px' }}>
					Top APR90s: 
				</div>
				<Stack direction="row" sx={{ width:"96%", flexWrap:'wrap', marginLeft: 1 }}>
					{ APR90Scores.map((apr:TAPRData) => { return renderAPRButton(apr, apr.score_gamma) }) }
				</Stack>
			</Stack>							
			</>
		)		
	}

	const quarantineSymbol = (symbol: string) => {
		goAPI("PUT", `/sec/quarantine?symbol=${symbol}`).then((go: goResult) => {
			if (go.httpstatus === 200) {
				const data:{ log: string[], tickers: string[] } = go.data
				data.log.forEach(item => {
					appendStatusText(item)
				})

				doGetTopAPRs()
			} else if (go.httpstatus === 401) {
				setConfig({...config, in_session: false})
			} else {
				console.log("Unexpected HTTP status: [" + go.httpstatus + ", " + JSON.stringify(go.data) + "]")
			}
		})
	}

	const changeActiveSymbol = (symbol:string) => {
		setActiveSymbol(symbol)
	}

	useEffect(() => {		
		if (refreshWatches == 0) {
			doGetSECWatches()
		}
		if (showAllAPRs) {
			//doGetTopAPRs()
		}
	}, [refreshWatches, showAllAPRs])

	//{ showShorts ? renderShortsList() : <></> }
	//{ renderAPRsList(showAllAPRs) }

	let key = 0
	let short_symbol:string = ""
	let long_symbol:string = ""
	return (
		<>
			<Stack direction='column' sx={{ paddingLeft: 1, marginTop: 1 }}>
				<Stack direction="column">
					
					{ showLongs ? renderLongsList() : <></> }
					
										
					<Stack direction='row'>
						<div style={{ height: 20, marginTop: 2, width: '75px' }}>
							Equities Filter:
						</div>
						<input 
							type='edit' 
							ref={filterRef}
							onChange={() => handleTickerEditChange()} 
							style={{ marginLeft: 10, height: 20, width: 80 }} 
						/>
						<div style={{ marginLeft: 10, marginTop: 2 }}>
							Matches: 
						</div>
						<div style={{ overflowX: 'clip', marginLeft: 10, height: 'auto', flex: 1 }}>
						{
							tickers.length < 1000 ?
								tickers.map(t => 
									<button 
										onClick={(e) => { handleTickerSelected(e.currentTarget.value) }} 										
										value={t} 
										style={{ 
											padding: 2, 
											textAlign:'center', 
											border:'solid', 
											borderWidth:1, 
											borderColor:'#ccc #000 #000 #ccc', 
											color: activeSymbol == t ? apr_font_highlight : apr_font_default, 
											backgroundColor: activeSymbol == t ? apr_background_highlight : apr_background_default,
											marginRight: 3, 
											marginBottom: 2 
										}}
										key={t}
									>
										{t}
									</button>
								)
							:<>Too many matches: {tickers.length}</>
						}
						</div>
					</Stack>
				</Stack>
				<Stack direction='column'>
					{
						symbols.map((symbol: string, i: number) => {
							return (
								<Stack direction="row" sx={{ marginTop: 2 }}>
									<SECNotes symbol={symbol} appendStatusText={appendStatusText}/>
									<EquityProfileChart 
										clearCache={ doClearCache } 
										closeChart={ doCloseChart }
										symbol={ symbols[i] } 
										activeSymbol={ activeSymbol }
										changeActiveSymbol={ changeActiveSymbol }
										issuername={ issuerNames[i] }
										daily_market={ dailyMarket[i] } 
										daily_insider={ dailyInsider[i] }
										edges={ aprDOWEdges[i] }

										alpha_air_score={ alphaAIRScore[i] }
										beta_air_score={ betaAIRScore[i] }
										gamma_air_score={ gammaAIRScore[i] }

										alpha_adr_score={ alphaADRScore[i] }
										beta_adr_score={ betaADRScore[i] }
										gamma_adr_score={ gammaADRScore[i] }

										appendStatusText = { appendStatusText }
										isShortWatched={ getIsShortWatched(symbol) }
										isLongWatched={ getIsLongWatched(symbol) }
										toggleShortWatch={ toggleShortWatch }
										toggleLongWatch={ toggleLongWatch }
										quarantineSymbol={ quarantineSymbol }
									/>
								</Stack>
						)})
					}
				</Stack>
			</Stack>
		</>
	)
}