// application key
export const app_key = 'iire.ai'

export const inspector_window = -1

export const app_background = '#000'

export const open_colour = 'gray'
export const close_colour = 'black'
export const high_colour = 'blue'
export const low_colour = 'red'
export const volume_colour = '#684448'
export const position_colour = 'orange'

export const apr_font_default = 'black'
export const apr_font_highlight = 'violet'
export const apr_font_dimmed = '#a8a8a8'
export const apr_background_default = '#f8f8f8'
export const apr_background_highlight = 'f8f8f8'

export const equity_profile_chart_height = 180

export const notes_width = 300
export const notes_height = equity_profile_chart_height

export const tier_img_width = 18
export const tier_img_height = 18

export const dev_api_port = 5011
export const prod_api_port = 5011

export const api_key = '75BSUIUyJjbRAG0L3mJK7HiwYG26PX9QgFclp6QjivGWKsqgO87TMZgl7KvTZhwWqhyOz7cyB3V2L8eAyOWYPuS1UIweLHCH96vAGcrw2oQiL1dnmhMTprhfsglCZTT3'

// reference_x is based on iPad Pro dimensions
export const reference_width = 1366
export const reference_height = 1024

// sidebar_width applies when window is >= 900 in width, height applies when window < 900 in width
export const sidebar_width = 80
export const sidebar_height = 50

// status is the grey info bar that shows at top of all pages
export const status_height = 150

// field_work_emulator_x are now obsolete
export const field_work_emulator_height_min = 0 //52
export const field_work_emulator_height_max = 0 //357

// calendar controls is the height of the calendar controls ribbon 
export const calendar_controls_height = 65

// calendar controls is the height of the milestones controls ribbon 
export const milestones_controls_height = 65

// card_panel_width 
export const card_panel_width = 340

// row_height is used extensively to give a consistant content density to pages
export const row_height = 22

// menu bar heights
export const menu_bar_height = 70
