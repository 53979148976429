import React from 'react'
import { 
	TDailyInsider, 
	TDailyMarket, 
	TSECSubmissionModel, 
	TSECNonDerivativeTransactionModel, 
	TSECDerivativeTransactionModel, 
	TSECReportingOwnerModel
} from '../../../types.tsx'
//import Stack from '@mui/material/Stack'
import { formatNumber } from '../../../utility/number.tsx'

const styles = {
	accession_number: {
		width: '160px'
	},
	form: {
		width: '50px'
	},
	rss_updated: {
		width: '100px'
	},	
	trans_acquired_disp_cd: {
		width: '30px'
	},
	trans_shares: {
		width: '80px'
	},
	trans_pricepershare: {
		width: '80px'
	},
	proceeds: {
		width: '100px'
	},
	shrs_ownd_folwng_trans: {
		width: '100px'
	},
	rptownername: {
		width: '150px'
	},
	rptowner_title: {
		width: '150px'
	},
	header: {
		backgroundColor: '#04aaff'
	},
	data: {
		
	},
	container: {
		border: '1px solid #888',
		width: 'auto'
	}
}

type TProps = {
	dow: number,
	symbol: string,
	issuername: string,
	daily_market: TDailyMarket,
	daily_insider: TDailyInsider,
}

export default function EquityDayDetail({ dow, symbol, issuername, daily_market, daily_insider }:TProps) {
	let current_an: string = ""
	let c: boolean = false

	return (
		<>
		<div style={{fontWeight:'bold'}}>Submissions</div>
		<table border={1}>
			{
				daily_insider[dow] ? daily_insider[dow].submissions ? daily_insider[dow].submissions.length > 0 ?
					<>
						<div style={styles.container}>
							<table>
								<tr>
									<td style={{...styles.header, ...styles.accession_number}}>Accession Number</td>
									<td style={{...styles.header, ...styles.form}}>Form</td>
									<td style={{...styles.header, ...styles.rss_updated}}>XML</td>
								</tr>
							</table>
							{
								daily_insider[dow].submissions.map((m: TSECSubmissionModel, i: number) => {
									if (i === 0) {
										current_an = ""
									}
									if (m.accession_number !== current_an) {
										current_an = m.accession_number
										c = !c
									}
									return (
										<table style={{ backgroundColor: (c?'#fff':'#f4f4f4')}}>
											<tr>
												<td style={{...styles.data, ...styles.accession_number}}>{m.accession_number}</td>
												<td style={{...styles.data, ...styles.form}}>{m.document_type}</td>
												<td style={{...styles.data, ...styles.rss_updated}}>
													{
														m.xml_url.length > 0 ?
															<button 
																onClick={() => window.open(m.xml_url, "window title", "") }
																style={{ 
																	border:'solid', 
																	borderWidth:1, 
																	borderColor:'#ccc #000 #000 #ccc', 
																	color: '#00f', 
																	margin: '2 0 2 0',
																	padding: '0 15 0 15'
																}}
															>
																	Filing
															</button> 
															: <></>
													}
												</td>
											</tr>
										</table> 
									)
								})
							}
						</div>
					</>
					: <></>
					: <></>
					: <></>
			}
			{
				daily_insider[dow] ? daily_insider[dow].nd_transactions ? daily_insider[dow].nd_transactions.length > 0 ?
					<>
						<div style={{fontWeight:'bold'}}>Non-Derivative Transactions</div>
						<div style={styles.container}>
							<table style={{ flex: 1, backgroundColor: '#04aaff' }}>
								<tr>
									<td style={{...styles.header, ...styles.accession_number}}>Accession Number</td>
									<td style={{...styles.header, ...styles.trans_acquired_disp_cd}}>A/D</td>
									<td style={{...styles.header, ...styles.trans_shares}}># Shares</td>
									<td style={{...styles.header, ...styles.trans_pricepershare}}>Price</td>
									<td style={{...styles.header, ...styles.proceeds}}>Proceeds</td>
									<td style={{...styles.header, ...styles.shrs_ownd_folwng_trans}}>Shares Remaining</td>
								</tr>
							</table>
							{
								daily_insider[dow].nd_transactions.map((m: TSECNonDerivativeTransactionModel, i: number) => {
									if (i === 0) {
										current_an = ""
									}
									if (m.accession_number !== current_an) {
										current_an = m.accession_number
										c = !c
									}
									return (
										<table style={{ backgroundColor: (c?'#fff':'#f4f4f4')}}>
											<tr>
												<td style={{...styles.data, ...styles.accession_number}}>{m.accession_number}</td>
												<td style={{...styles.data, ...styles.trans_acquired_disp_cd}}>{m.trans_acquired_disp_cd}</td>
												<td style={{...styles.data, ...styles.trans_shares}}>{m.trans_shares}</td>
												<td style={{...styles.data, ...styles.trans_pricepershare}}>{(m.trans_pricepershare)}</td>
												<td style={{...styles.data, ...styles.proceeds}}>${formatNumber((m.trans_pricepershare*m.trans_shares))}</td>
												<td style={{...styles.data, ...styles.shrs_ownd_folwng_trans}}>{m.shrs_ownd_folwng_trans}</td>
											</tr>
										</table> 
									)
								})
							}
						</div>
					</>
					:<></>
					:<></>
					:<></>
			}
			{
				daily_insider[dow] ? daily_insider[dow].d_transactions ? daily_insider[dow].d_transactions.length > 0 ?
					<>
						<div style={{fontWeight:'bold'}}>Derivative Transactions</div>
						<div style={styles.container}>
							<table style={{ flex: 1, backgroundColor: '#04aaff' }}>
								<tr>
									<td style={{...styles.header, ...styles.accession_number}}>Accession Number</td>
									<td style={{...styles.header, ...styles.trans_acquired_disp_cd}}>A/D</td>
									<td style={{...styles.header, ...styles.trans_shares}}># Shares</td>
									<td style={{...styles.header, ...styles.trans_pricepershare}}>Price</td>
									<td style={{...styles.header, ...styles.proceeds}}>Proceeds</td>
									<td style={{...styles.header, ...styles.shrs_ownd_folwng_trans}}>Shares Remaining</td>
								</tr>
							</table>
							{
								daily_insider[dow].d_transactions.map((m: TSECDerivativeTransactionModel, i: number) => {
									if (i === 0) {
										current_an = ""
									}
									if (m.accession_number !== current_an) {
										current_an = m.accession_number
										c = !c
									}
									return (
										<table style={{ backgroundColor: (c?'#fff':'#f4f4f4')}}>
											<tr>
												<td style={{...styles.data, ...styles.accession_number}}>{m.accession_number}</td>
												<td style={{...styles.data, ...styles.trans_acquired_disp_cd}}>{m.trans_acquired_disp_cd}</td>
												<td style={{...styles.data, ...styles.trans_shares}}>{m.trans_shares}</td>
												<td style={{...styles.data, ...styles.trans_pricepershare}}>{m.trans_pricepershare}</td>
												<td style={{...styles.data, ...styles.proceeds}}>${(m.trans_pricepershare*m.trans_shares).toFixed(2)}</td>
												<td style={{...styles.data, ...styles.shrs_ownd_folwng_trans}}>{m.shrs_ownd_folwng_trans}</td>
											</tr>
										</table> 
									)
								})
							}
						</div>
					</>
					: <></>
					: <></>
					: <></>
			}
			{

				daily_insider[dow] ? daily_insider[dow].reporters ? daily_insider[dow].reporters.length > 0 ?
					<>
						<div style={{fontWeight:'bold'}}>Reporters</div>
						<div style={styles.container}>
							<table style={{ flex: 1, backgroundColor: '#04aaff' }}>
								<tr>
									<td style={{...styles.header, ...styles.accession_number}}>Accession Number</td>
									<td style={{...styles.header, ...styles.rptownername}}>Name</td>
									<td style={{...styles.header, ...styles.rptowner_title}}>Title</td>
								</tr>
							</table>
							{
								daily_insider[dow].reporters.map((m: TSECReportingOwnerModel, i: number) => {
									if (i === 0) {
										current_an = ""
									}
									if (m.accession_number !== current_an) {
										current_an = m.accession_number
										c = !c
									}
									return (
										<table style={{ backgroundColor: (c?'#fff':'#f4f4f4')}}>
											<tr>
												<td style={{...styles.data, ...styles.accession_number}}>{m.accession_number}</td>
												<td style={{...styles.data, ...styles.rptownername}}>{m.rptownername}</td>
												<td style={{...styles.data, ...styles.rptowner_title}}>{m.rptowner_title}</td>
											</tr>
										</table> 
									)
								})
							}
						</div>
					</>
					: <></>
					: <></>
					: <></>
			}			
		</table>
	</>)
}