import { Dispatch, SetStateAction, createContext } from "react"

export type TAppConfig = {
	session_token: string,
	in_session: boolean
}
export const default_app_config = {
	session_token: '',
	in_session: false
}
Object.freeze(default_app_config)

export type TAppContext = {
  config: TAppConfig,
  setConfig: Dispatch<SetStateAction<TAppConfig>>
}
export const default_app_context:TAppContext = {
	config: default_app_config,
	setConfig: () => {}
}
Object.freeze(default_app_context)

export const AppContext = createContext<TAppContext>(default_app_context)

export type TTickerMetadata = {
	issuername: string
}
export const default_ticker_metadata:TTickerMetadata = {
	issuername: ''
}
Object.freeze(default_ticker_metadata)

export type TDailyMarket = {
	[key: number]: {
		date: number,
		open: number,
		news: string[]
	}
}

export type TSECSubmissionModel = {
	accession_number: string,
	filing_date: number,
	period_of_report: number,
	date_of_orig_sub: number,
	no_securities_owned: string,
	not_subject_sec16: string,
	form3_holding_reported: string,
	form4_trans_reported: string,
	document_type: string,
	issuercik: string,
	issuername: string,
	issuertradingsymbol: string,
	remarks: string,
	rss_updated: string,
	xml_url: string
}
export type TSECReportingOwnerModel = {
	accession_number: string,
	rptownercik: string,
	rptownername: string,
	rptowner_relationship: string,
	rptowner_title: string,
	rptowner_txt: string,
	rptowner_street1: string,
	rptowner_street2: string,
	rptowner_city: string,
	rptowner_state: string,
	rptowner_zipcode: string,
	rptowner_state_desc: string,
	file_number: string
}
export type TSECDerivativeTransactionModel = {
	accession_number: string,
	deriv_trans_sk: number,
	security_title: string,
	security_title_fn: string,
	conv_exercise_price: number,
	conv_exercise_price_fn: string,
	trans_date: number,
	trans_date_fn: string,
	deemed_execution_date: number,
	deemed_execution_date_fn: string,
	trans_form_type: string,
	trans_code: string,
	equity_swap_involved: boolean,
	equity_swap_trans_cd_fn: string,
	trans_timeliness: string,
	trans_timeliness_fn: string,
	trans_shares: number,
	trans_shares_fn: string,
	trans_total_value: number,
	trans_total_value_fn: string,
	trans_pricepershare: number,
	trans_pricepershare_fn: string,
	trans_acquired_disp_cd: string,
	trans_acquired_disp_cd_fn: string,
	exercise_date: number,
	exercise_date_fn: string,
	expiration_date: number,
	expiration_date_fn: string,
	undlyng_sec_title: string,
	undlyng_sec_title_fn: string,
	undlyng_sec_shares: number,
	undlyng_sec_shares_fn: string,
	undlyng_sec_value: number,
	undlyng_sec_value_fn: string,
	shrs_ownd_folwng_trans: number,
	shrs_ownd_folwng_trans_fn: string,
	valu_ownd_folwng_trans: number,
	valu_ownd_folwng_trans_fn: string,
	direct_indirect_ownership: string,
	direct_indirect_ownership_fn: string,
	nature_of_ownership: string,
	nature_of_ownership_fn: string
}
export type TSECNonDerivativeTransactionModel = {
	accession_number: string,
	nonderiv_trans_sk: number,
	security_title: string,
	security_title_fn: string,
	trans_date: number,
	trans_date_fn: string,
	deemed_execution_date: number,
	deemed_execution_date_fn: string,
	trans_form_type: string,
	trans_code: string,
	equity_swap_involved: boolean,
	equity_swap_trans_cd_fn: string,
	trans_timeliness: string,
	trans_timeliness_fn: string,
	trans_shares: number,
	trans_shares_fn: string,
	trans_pricepershare: number,
	trans_pricepershare_fn: string,
	trans_acquired_disp_cd: string,
	trans_acquired_disp_cd_fn: string,
	shrs_ownd_folwng_trans: number,
	shrs_ownd_folwng_trans_fn: string,
	valu_ownd_folwng_trans: number,
	valu_ownd_folwng_trans_fn: string,
	direct_indirect_ownership: string,
	direct_indirect_ownership_fn: string,
	nature_of_ownership: string,
	nature_of_ownership_fn: string
}
export type TSECFootnotesModel = {
	accession_number: string,
	footnote_id: string,
	footnote_txt: string
}

export type TInsiderDay = {
	symbol: string,
	_dow: number,
	date: number,
	position: number,
	submissions: TSECSubmissionModel[],
	reporters: TSECReportingOwnerModel[],
	d_transactions: TSECDerivativeTransactionModel[],
	nd_transactions: TSECNonDerivativeTransactionModel[],
	footnotes: TSECFootnotesModel[]
}
export type TDailyInsider = {
	[key: number]: TInsiderDay
}

export type TEquityData = {
	issuername: string,
	issuertradingsymbol: string,
	prices: {[key: number]: number},
	positions: {[key: number]: number}
}
export const default_equity_data: TEquityData = {
	issuername: '',
	issuertradingsymbol: '',
	prices: {},
	positions: {}
}
Object.freeze(default_equity_data)

export type TSECWatch = {	
	issuer_id: number,
	user_id: number,
	use_email_notification: number,
	use_sms_notification: number,
	keyword: string,
	type: number,
	tier: number
}
export const default_sec_watch: TSECWatch = {
	issuer_id: 0,
	user_id: 0,
	use_email_notification: 0,
	use_sms_notification: 0,
	keyword: "",
	type: 0,
	tier: 0
}
Object.freeze(default_sec_watch)

export type TAPRData = {
	apr_configuration_id: number,
	score_alpha: number,
	score_beta: number,
	score_gamma: number,
	degree: number,
	compute_date: number,
	inspection_date: number,
	symbol: string,
	state: number,
	tier: number
}
export const default_apr_data:TAPRData = {
	apr_configuration_id: 0,
	score_alpha: 0,
	score_beta: 0,
	score_gamma:0,
	degree: 0,
	compute_date: 0,
	inspection_date: 0,
	symbol: "",
	state: 0,
	tier: 0
}
Object.freeze(default_apr_data)

export type TSECNote = {
	id: number,
	user_id: number,
	symbol: string,
	text: string,
	type: number,
	state: number
}
export const default_sec_note:TSECNote = {
	id: 0,
	user_id: 0,
	symbol: "",
	text: "",
	type: 0,
	state: 0
}
Object.freeze(default_sec_note)