import React, { useState, useContext } from 'react'
import { goAPI, goResult, storeAppConfig } from './utility/go.tsx'
import { TAppContext, AppContext, TAppConfig } from './types.tsx'
import { Stack } from '@mui/material'

import "./Login.css"

export default function Login() {
  const {config, setConfig}:TAppContext = useContext(AppContext)
  const [showRetry, setShowRetry] = useState(false)
  const [pwd, setPwd] = useState("")
  const [email, setEmail] = useState("")  
  
  const doLogin = () => {
    goAPI("POST", "/login?email=" + email, { p: pwd }).then((go: goResult) => {
      if (go.httpstatus === 200) {
        let new_config:TAppConfig = {...go.data}				
        new_config.in_session = true
        storeAppConfig(new_config)
        setConfig(new_config)        
      } else {
        setConfig({...config, in_session: false})
        setShowRetry(true)
        console.log("Login failed: [" + go.httpstatus + ", " + JSON.stringify(go.data) + "]")
      }
    })
  }

  let header_title = "IIRE.AI"
  let header_sub = "Area51"

  if (!config.in_session && !showRetry) {
    let box_title = "Secure Login"
    let box_instruction = "Enter your email and password to log in:"

    return (
      <div className='main_box'>
        <div className="main_box--header">
          <h2>
            <b>{header_title}™</b>
            <br />
            {header_sub}
          </h2>
        </div>
        <div className="main_box--main">
          <div className="main_box--main--title">
            <h4>{box_title}</h4>
            <p>{box_instruction}</p>
          </div>
          <div className="main_box--main--login">
            <input 
              type="text" 
              id="username" 
              placeholder="email" 
              onChange={ e => setEmail(e.target.value) } 
              style={{
                width: '170px'
              }}              
            />
            <input 
              type="password" 
              id="password" 
              placeholder="password" 
              onChange={ e => setPwd(e.target.value) }
              style={{
                width: '170px',
                marginLeft: '20px'
              }}
            />
            <button 
              className="btn btn-success" 
              onClick={() => doLogin()}
            >
              LOGIN
            </button>
          </div>
        </div>
      </div>
    );
  } else if (showRetry) {
    let box_title = "Secure Login - Failed";
    let box_instruction = "Please check your credentials and try again.";

    return (
      <div>
        <div className="main_box">
          <div className="main_box--header">
            <h2>
              <b>{header_title}&#8482;</b>
              <br />
              {header_sub}
            </h2>

          </div>
          <div className="main_box--main">
            <div className="main_box--main--title">
              <h4>{box_title}</h4>
              <p>{box_instruction}</p>
            </div>
            <div className="main_box--main--login">
              <button className="btn btn-success" onClick={ () => { setShowRetry(false); } }>
                TRY AGAIN
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return <></>
  }
}